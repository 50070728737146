import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Gadget Guru Repairs
			</title>
			<meta name={"description"} content={"Your reliable partner in equipment repair"} />
			<meta property={"og:title"} content={"Gadget Guru Repairs"} />
			<meta property={"og:description"} content={"Your reliable partner in equipment repair"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section
			padding="120px 0 120px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_331669175-scaled-1.jpg?v=2024-07-25T12:51:47.461Z) center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline4"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
					text-transform="uppercase"
				>
					CONTACT US TODAY AND WE WILL BE HAPPY TO HELP YOU SOLVE ANY PROBLEMS WITH YOUR DEVICES.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 50px 0" background="--color-dark" quarkly-title="Footer-13" sm-padding="50px 0 50px 0">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 80px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
				md-margin="0px 0px 50px 0px"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--primary"
						md-margin="0px 0px 20px 0px"
					>
						Address
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						2/22 Dargie Ct, Dallas VIC 3047,{" "}
						<br />
						Australia
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--primary"
						md-margin="0px 0px 20px 0px"
					>
						Contacts information
					</Text>
					<Link
						href="+61393097933"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						text-align="center"
						margin="0px 0px 15px 0px"
						hover-color="#f5eceb"
					>
						+61393097933
					</Link>
					<Link
						href="info@luminarolux.com"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						margin="0px 0px 15px 0px"
						text-align="center"
						hover-color="#f5eceb"
					>
						hello@luminarolux.com
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--primary"
						md-margin="0px 0px 20px 0px"
					>
						Working Hours
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Mo-Fr: 10:00 - 19:00
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Saturday-Sunday: Closed
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1">
				2/22 Dargie Ct, Dallas VIC 3047, Australia
				<br />
				<br />
				+61393097933
			</Override>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});